<template>

  <!-- Table Container Card -->
  <b-row>
      <b-col
      xl="12"
      lg="12"
      md="12"
      >
        <b-card
            no-body
        >
            <!-- 소프트웨어 정보 조회 -->
            <b-table
            ref="refInvoiceListTable"
            :items="softwareListItem"
            responsive
            :fields="softwareColums"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc"
            class="position-relative text-center mb-0"
            >
            </b-table>
            <!-- 소프트웨어 정보 조회 END -->
        </b-card>
    </b-col>
    <b-col
      xl="12"
      lg="12"
      md="12"
      >
        <b-card
            no-body
        >
            <!-- Audio Video In Out 정보 조회 -->
            <b-table
            ref="refInvoiceListTable"
            :items="aVInOutListItem"
            responsive
            :fields="aVInOutColums"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc"
            class="position-relative text-center mb-0"
            >
            </b-table>
            <!-- Audio Video In Out 정보 조회 END -->
        </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BTable, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {
      },
    }
  },
  setup() {
    const softwareColums = [
        { key: 'software', label: '소프트웨어 버전'},
        { key: 'uptime', label: 'Uptime Seconds', },
        { key: 'cdr_time', label: 'CDR Time' },
        { key: 'activated', label: 'Activated'},
        { key: 'cluster', label: 'Cluster Enabled' },
        { key: 'call_active', label: 'CallLegs Active' },
        { key: 'call_max_active', label: 'CallLegs Max Active' },
        { key: 'call_completed', label: 'CallLegs Completed' },
    ]
    const softwareListItem = [
        { 'software': '2.9.2',
          'uptime': '7878313',
          'cdr_time': '2021-02-11 19:44:46',
          'activated': 'true',
          'cluster': 'false',
          'call_active': '0',
          'call_max_active': '505',
          'call_completed': '894686',
        },
    ]
    const aVInOutColums = [
        { key: 'audio_outgoing', label: 'Audio BitRate Outgoing'},
        { key: 'audio_incoming', label: 'Audio BitRate Incoming', },
        { key: 'video_outgoing', label: 'Video BitRate Outgoing' },
        { key: 'video_incoming', label: 'Video BitRate Incoming'},
    ]
    const aVInOutListItem = [
        { 'audio_outgoing': '0',
          'audio_incoming': '0',
          'video_outgoing': '0',
          'video_incoming': '0',
        },
    ]
    return {
      softwareListItem,
      softwareColums,
      aVInOutColums,
      aVInOutListItem,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
