<template>
<b-row>
    <b-col
    xl="12"
    lg="12"
    md="12"
    class="ecs-card"
    >
        <b-card>
            <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            class="ecs-card"
            >
                <h4 class="w-100 font-weight-bolder mb-1 p-t-5">시스템 상태 조회</h4>
            </b-col>
            <b-tabs>
            <b-tab title="api_server_1">
                <b-row>
                    <b-col
                    xl="6"
                    lg="6"
                    md="12"
                    class="ecs-card"
                    >
                        <b-card no-body>
                            <!-- chart -->
                            <b-card-body class="p-0">
                            <sys-chart
                                :height="50"
                                :width="100"
                                :data="SysChartJs.callLegsChart.data"
                                :options="SysChartJs.callLegsChart.options"
                                :plugins="plugins"
                            />
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col
                    xl="6"
                    lg="6"
                    md="12"
                    class="ecs-card"
                    >
                        <b-card no-body>
                            <!-- chart -->
                            <b-card-body class="p-0">
                            <sys-chart
                                :height="50"
                                :width="100"
                                :data="SysChartJs.videoAudioChart.data"
                                :options="SysChartJs.videoAudioChart.options"
                                :plugins="plugins"
                            />
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab
                title="api_server_2"
            >
                <b-row>
                    <b-col
                    xl="6"
                    lg="6"
                    md="12"
                    class="ecs-card"
                    >
                        <b-card no-body>
                            <!-- chart -->
                            <b-card-body class="p-0">
                            <sys-chart
                                :height="50"
                                :width="100"
                                :data="SysChartJs.callLegsChart.data"
                                :options="SysChartJs.callLegsChart.options"
                                :plugins="plugins"
                            />
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col
                    xl="6"
                    lg="6"
                    md="12"
                    class="ecs-card"
                    >
                        <b-card no-body>
                            <!-- chart -->
                            <b-card-body class="p-0">
                            <sys-chart
                                :height="50"
                                :width="100"
                                :data="SysChartJs.videoAudioChart.data"
                                :options="SysChartJs.videoAudioChart.options"
                                :plugins="plugins"
                            />
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-tab>
            </b-tabs>
        </b-card>
    </b-col>
    <b-col
    xl="12"
    lg="12"
    md="12"
    class="ecs-card"
    >
        <sys-list></sys-list>
    </b-col>
</b-row>
</template>

<script>

import { BRow, BCol, BTabs, BTab, BCard, BCardBody } from 'bootstrap-vue'

import SysChart from './components/SysChart.vue'
import SysChartJs from './components/SysChart'
import SysList from './components/SysStateList.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BCardBody,

    SysChart,
    SysList,
  },
  data() {
    return {
      data: {},
      SysChartJs,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 0
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
}
</script>
