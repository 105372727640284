
// *===============================================---*
// *--------- 차트 컬러 -------------------------*
// *===============================================---*
// const chartColors = {
//   primaryColorShade: '#836AF9',
//   yellowColor: '#ffe800',
//   successColorShade: '#28dac6',
//   warningColorShade: '#ffe802',
//   warningLightColor: '#FDAC34',
//   infoColorShade: '#299AFF',
//   greyColor: '#4F5D70',
//   blueColor: '#2c9aff',
//   blueLightColor: '#84D0FF',
//   greyLightColor: '#EDF1F4',
//   tooltipShadow: 'rgba(0, 0, 0, 0.25)',
//   lineChartPrimary: '#666ee8',
//   lineChartDanger: '#ff4961',
//   labelColor: '#6e6b7b',
//   grid_line_color: 'rgba(200, 200, 200, 0.2)',
// }
// *===============================================---*
// * 그래프 라인 차트 *
// * [progrsMetChart / 진행중인 미팅] *
// * [atndTrmnlChart / 참석 단말 수] *
// * [videoOutChart / VIDEO OUTGOING] *
// * [videoInChart / VIDEO INCOMING] *
// * [audioOutChart / AUDIO OUTGOING] *
// * [audioInChart / AUDIO INCOMING] *
// * 그래프 도넛 차트 *
// *===============================================---*
export default {
  callLegsChart: {
    options: {
      layout: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 20,
        },
      },
      scales: {
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'bit/s',
            },
            ticks: {
              stepSize: 100,
              min: 0,
              max: 600,
              beginAtZero: true,
            },
          },
        ],
      },
    },
    data: {
      labels: 
      [
        '13:43:41', '13:43:46', '13:43:51', '13:43:56', '13:44:01', '13:44:06', '13:44:11', '13:44:16', '13:44:21', '13:44:26',
        '13:44:31', '13:44:36', '13:44:41', '13:44:46', '13:44:51', '13:44:56', '13:45:01', '13:45:06', '13:45:11', '13:45:16',
      ],
      datasets: [
        {
          data: 
          [
            500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
            500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          ],
          label: 'call Legs Active',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          fill: false,
        },
        {
          data: 
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          label: 'call Legs MaxActive',
          backgroundColor: 'rgba(255, 193, 7, 0.2)',
          borderColor: 'rgba(255, 193, 7, 1)',
          borderWidth: 2,
          fill: false,
        }
      ],
    },
  },
  videoAudioChart: {
    options: {
        layout: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 20,
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'bit/s',
              },
              ticks: {
                stepSize: 0.2,
                min: -1.0,
                max: 1.0,
                beginAtZero: true,
              },
            },
          ],
        },
      },
      data: {
        labels: 
        [
          '13:43:41', '13:43:46', '13:43:51', '13:43:56', '13:44:01', '13:44:06', '13:44:11', '13:44:16', '13:44:21', '13:44:26',
          '13:44:31', '13:44:36', '13:44:41', '13:44:46', '13:44:51', '13:44:56', '13:45:01', '13:45:06', '13:45:11', '13:45:16',
        ],
        datasets: [
          {
            data: 
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            label: 'video Bit Ragte Outgoing',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            data: 
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            label: 'video Bit Rate Incoming',
            backgroundColor: 'rgba(255, 193, 7, 0.2)',
            borderColor: 'rgba(255, 193, 7, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            data: 
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            label: 'audio Bit Ragte Outgoing',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            data: 
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            label: 'audio Bit Rate Incoming',
            backgroundColor: 'rgba(165, 147, 224, 0.2)',
            borderColor: 'rgba(165, 147, 224, 1)',
            borderWidth: 2,
            fill: false,
          }
        ],
      },
  },
  mounted() {
    const value = '테스트'
    this.textCenter(value)
  },
  methods: {
  },
}
